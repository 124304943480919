export function SupportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6668 16.6668V4.81516C16.6668 3.99683 16.0035 3.3335 15.1852 3.3335H4.81516C3.99683 3.3335 3.3335 3.99683 3.3335 4.81516V13.7043C3.3335 14.5227 3.99683 15.186 4.81516 15.186H13.8893L16.6668 16.6668Z"
        stroke="#7C7C7C"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2365 10.8335C11.4223 12.6477 8.48067 12.6477 6.6665 10.8335"
        stroke="#7C7C7C"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33317 6.6665V7.49984"
        stroke="#7C7C7C"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 6.6665V7.49984"
        stroke="#7C7C7C"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
