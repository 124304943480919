export function ProfileImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
    >
      <mask
        id="mask0_387_2951"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="88"
        height="88"
      >
        <path
          d="M88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88C68.3005 88 88 68.3005 88 44Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_387_2951)">
        <path d="M87.9995 0H-0.000488281V88H87.9995V0Z" fill="black" />
        <path
          d="M31.7772 91.8831C52.8221 104.033 79.7321 96.8228 91.8823 75.778C104.033 54.7331 96.8221 27.8231 75.7772 15.6729C54.7323 3.52259 27.8224 10.7331 15.6721 31.778C3.52183 52.8228 10.7323 79.7328 31.7772 91.8831Z"
          fill="url(#paint0_linear_387_2951)"
        />
        <path
          d="M36.6665 34.2222C41.5554 36.6666 46.4443 36.6666 51.3332 34.2222"
          stroke="#FFAD08"
          stroke-width="2.44444"
          stroke-linecap="round"
        />
        <path
          d="M37.8888 24.4444C37.8888 23.0944 37.068 22 36.0555 22C35.043 22 34.2222 23.0944 34.2222 24.4444C34.2222 25.7945 35.043 26.8889 36.0555 26.8889C37.068 26.8889 37.8888 25.7945 37.8888 24.4444Z"
          fill="#FFAD08"
        />
        <path
          d="M52.5553 24.4444C52.5553 23.0944 51.7345 22 50.722 22C49.7095 22 48.8887 23.0944 48.8887 24.4444C48.8887 25.7945 49.7095 26.8889 50.722 26.8889C51.7345 26.8889 52.5553 25.7945 52.5553 24.4444Z"
          fill="#FFAD08"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_387_2951"
          x1="33"
          y1="16.5002"
          x2="31.7772"
          y2="91.8831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#212427" />
          <stop offset="0.868246" stop-color="#130E03" />
        </linearGradient>
      </defs>
    </svg>
  );
}
